@import 'src/styles/colors.scss', 'src/styles/typography.scss', 'src/styles/breakpoints.scss';

@include hidden;

a {
  text-decoration: none;
}

.Contact__Page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include MainFont;
  @include breakpoint-styles;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    justify-content: flex-start;
  }

  @media screen and (max-height: $vertical) {
    align-items: flex-start;
    padding: 2% 0;
  }
}

.Contact__IconContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  @media screen and (max-width: $mobile) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0;
  }
}

.Contact__IconContainer > * {
  margin: .5rem 0;

  @media screen and (max-width: $mobile) {
    margin: 0 .25rem;
  }
}

.Contact__Icon svg, #darkroom {
  width: 3rem;
  height: 3rem;
  
  &:hover {
    path {
      @include transition-250ms-ease;
      fill: $white-semiSolid;
    }
  }
}

.Contact__Icon svg path, #darkroom path {
  fill: $white-split;
}

#darkroom path {
  transform: translate(12%, 5%);
  transform-origin: center;
}