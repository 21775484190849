@import 'src/styles/colors.scss', 'src/styles/breakpoints.scss';

.App__Container {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.App {
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  position: relative;
  
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column-reverse;

    .Nav {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 500000000000;
    }
  }
}

.App__BgNext,
.App__BgCurrent {
  grid-area: 1 / 1 / 2 / 2;
  opacity: 1;

  &.hide {
    opacity: 0;
  }
}

.Home {
  background-color: $red-light;
  background-image: linear-gradient(-45deg, $red-dark, $red-light);
  @include background-animation;
}

.Me {
  background-color: $green-light;
  background-image: linear-gradient(-45deg, $green-dark, $green-light);
  @include background-animation;
}

.Experience {
  background-color: $purple-light;
  background-image: linear-gradient(-45deg, $purple-dark, $purple-light);
  @include background-animation;
}

.Work {
  background-color: $blue-light;
  background-image: linear-gradient(-45deg, $blue-dark, $blue-light);
  @include background-animation;
}

.Writing {
  background-color: $lime-light;
  background-image: linear-gradient(-45deg, $lime-dark, $lime-light);
  @include background-animation;
}

.Contact {
  background-color: $orange-light;
  background-image: linear-gradient(-45deg, $orange-dark, $orange-light);
  @include background-animation;
}

.Main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;

  @media screen and (max-height: $vertical) {
    align-items: flex-start;
  }


  @media screen and (max-width: calc($mobile + 100px)) {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}