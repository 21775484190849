$blue-dark: #1155f5;
$blue-light: #3F8EFC;
$blue-accent: #a5bdde;

$green-dark: #61675C;
$green-light: #387780;
$green-accent: #678b91;

$purple-dark: #3B3B58;
$purple-light: #7A5980;

$red-dark: #AD343E;
$red-light: #D8A48F;
$red-accent: #e3ad97;

$lime-dark: #537A5A;
$lime-light: #D0D6B5;
$lime-accent: #b9c19a;

$gold-dark: #EC9F05;
$gold-light: #F5BB00;

$orange-dark: #CC5803;
$orange-light: #F7934C;
$orange-accent: #eeaf81;

$magenta-light: #B4436C;
$magenta-dark: #7f2949;
$magenta-accent: #d78ea9;

$project-detail-bg-1: #342E37;
$project-detail-bg-2: #A2ABB5;

$white-transparent: rgba(255, 255, 255, 0.105);
$white-transparent200ish: rgba(255, 255, 255, 0.187);
$white-transparent400: rgba(255, 255, 255, 0.447);
$white-split: rgba(255, 255, 255, 0.571);
$white-semiSolid: rgba(255, 255, 255, 0.749);

$not-black: #161616;
$light-grey: #cccccc;

@mixin hidden {
  .Hidden {
    opacity: 0;
  }
}

@mixin background-animation {
  background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes button-pulse {
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color: $white-transparent;
  }
}

@mixin button-pulse-animation {
  animation: button-pulse 3s ease infinite;
}