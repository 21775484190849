@import 'src/styles/breakpoints.scss';

.Nav {
  height: 100%;
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-left: 1rem;

  @media screen and (max-height: $vertical) {
    justify-content: flex-start;
  }

  @media screen and (max-width: $mobile) {
    width: 100%;
    height: 3rem;
    padding-bottom: 1rem;
    flex-direction: row;
    padding-left: unset;
    justify-content: center;
  }
}

.Nav > * {
  margin: .5rem 0;

  @media screen and (max-width: $mobile) {
    margin: 0 .75rem;
  }
}

.Nav__Button {
  z-index: 2;
  margin-left: -10rem;
  transition: margin-left .2s ease;

  @media screen and (max-width: $mobile) {
    margin-left: 0;
    margin-bottom: -12rem;
    transition: margin-bottom .2s ease;
  }
}

.Visible {
  margin-left: 0;

  @media screen and (max-width: $mobile) {
    margin-bottom: 0;
  }
}