@import 'src/styles/colors.scss', 'src/styles/typography.scss', 'src/styles/breakpoints.scss';

@include hidden;

.Work__Page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include MainFont;
  @include breakpoint-styles;
}

.Work__CardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 80rem;
  min-height: 18rem;
  column-gap: 1.95rem;
  row-gap: 1.95rem;

  @media screen and (max-width: $mobile) {
    height: 50%;
    gap: 1rem;
  }
}

.Work__NavContainer {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-self: flex-end;
}

.Work__NavContainer > * {
  margin: 0 1rem;
}

.Work__Prev,
.Work__Next {
  cursor: pointer;
  color: $white-split;
  -webkit-tap-highlight-color: transparent;
  padding: 1.5rem;
  border-radius: 50%;
  background-color: $white-transparent;
  border: 1px solid $white-transparent200ish;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  &:hover {
    color: $white-semiSolid;
    background-color: $white-transparent200ish;
    border: 1px solid $white-transparent400;
  }

  svg {
    width: 1rem;
  }
}