@import 'src/styles/colors.scss', 'src/styles/typography.scss', 'src/styles/breakpoints.scss';

.EmailForm__Container {
  width: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include MainFont;
  @include transition-250ms-ease;

  @media screen and (max-width: $mobile) {
    width: 80vw
  }
}

.EmailForm__Container > * {
  margin: .5rem 0;
}

.EmailForm__Textarea,
.EmailForm__Input {
  background-color: $white-transparent;
  color: white;
  font-size: $xsmall;
  height: 15rem;
  padding: .5rem;
  border-radius: 4px;
  border: 1px solid $white-transparent400;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  @include MainFont;

  &::placeholder {
    color: $white-split;
  }
}

.EmailForm__Input {
  height: 1.5rem;
}

.EmailForm__BottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.EmailForm__Email {
  @include MainFont;
  font-size: $small;
  font-weight: 600;
  color: $white-semiSolid;
  @include transition-250ms-ease;

  @media screen and (max-width: $mobile) {
    font-size: $small-mobile;
  }
}

.EmailForm__Submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  align-self: flex-end;
  background-color: $white-transparent;
  color: $white-semiSolid;
  border: 1px solid $white-transparent400;
  cursor: pointer;
  @include transition-250ms-ease;

  &:hover {
    background-color: $white-transparent200ish;
  }
}