@import 'src/styles/colors.scss', 'src/styles/typography.scss', 'src/styles/breakpoints.scss';

@keyframes glow {
  0% { filter: drop-shadow(0px 0px 4px $white-semiSolid); }
  50% { filter: drop-shadow(0px 0px 1px $white-semiSolid); }
  100% { filter: drop-shadow(0px 0px 4px $white-semiSolid); }
}

.ProjectCard {
  width: 20rem;
  min-height: 6rem;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white-transparent;
  border-radius: 4px;
  color: $white-semiSolid;
  @include MainFont;
  font-size: $mediumMini;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
  @include transition-250ms-ease;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  
  &:hover {
    background-color: $white-transparent200ish;
    transform: scale(1.025) translateY(-2%);
  }

  &:active {
    transform: scale(.99) translateY(.5%);
  }

  @media screen and (max-width: $mobile) {
    width: 70vw;
    font-size: $medium-mobile;
  }
}

.ProjectCard__Desc {
  font-size: $xsmall;
  font-weight: 500;
  margin-top: .5rem;

  @media screen and (max-width: $mobile) {
    font-size: $small-mobile;
  }
}

.ProjectCard__Icon {
  position: absolute;
  padding-right: .5rem;
  color: $white-semiSolid;
  top: 0;
  right: 0;
  animation: glow 3s linear infinite;
  transition: all 250ms ease;

  &.Hovered {
    top: -1.75rem;
  }
}

.ProjectCard__Label {
  position: absolute;
  opacity: 0;
  right: -100%;
  top: 0.45rem;
  transition: all 100ms ease;
  font-size: $xsmall;
  font-weight: 500;
  white-space: nowrap;

  &.Show {
    right: 1.75rem;
    opacity: 1;
  }
}

.ProjectCard__Icon > svg {
  width: 1rem;
}