@import 'src/styles/colors.scss', 'src/styles/typography.scss', 'src/styles/breakpoints.scss';

@include hidden;

.Home__Page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @include MainFont;
  @include Italic;

  @media screen and (max-width: $mobile) {
    margin-top: -2.5rem;
    align-self: center;
  }
}

.Float {
  animation: float 10s ease infinite;
}

@keyframes float {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }
}

.Home__Lead {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: $large;
  color: $white-semiSolid;
  font-weight: 600;
  margin-bottom: 2rem;
  @include transition-250ms-ease;
  @include text-3D($red-accent);

  &.NotChrome {
    height: calc($large + 3.5rem);
    margin-bottom: -.2rem;

    @media screen and (max-width: $mobile) {
      height: 8.5rem;
    }
  }

  &.Long {
    &.NotChrome {
      @media screen and (max-width: $mobile) {
        height: calc($medium + 3.5rem);
      }
    }

    @media screen and (max-width: $mobile) {
      font-size: $medium;
    }
  }

  @media screen and (max-width: $mobile) {
    font-size: 5rem;
  }
}

.Home__Sub {
  color: $white-split;
  font-size: $medium;
  font-weight: 400;
  margin: .08rem 0;
  padding: 0;
  @include transition-250ms-ease;

  @media screen and (max-width: $mobile) {
    font-size: $medium-mobile;
  }
}

.Italic {
  @include Italic;
  color: $white-semiSolid;
}