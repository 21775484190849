@import 'src/styles/colors.scss', 'src/styles/breakpoints.scss';

.Button {
  color: $white-semiSolid;
  background-color: transparent;
  backdrop-filter: blur(10px);
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  -webkit-box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.32), -8px -8px 8px -6px rgba(255, 255, 255, 0.276); 
  box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.32), -8px -8px 8px -6px rgba(255,255,255,0.276);

  &:hover {
    background-color: $white-transparent;
    background: -moz-linear-gradient(162deg, $white-transparent 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(162deg, $white-transparent 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(162deg, $white-transparent 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$white-transparent,endColorstr="#ffffff",GradientType=1);
  }

  &.Active {
    background-color: $white-transparent;
    background: -moz-linear-gradient(162deg, $white-transparent200ish 0%, rgba(255, 255, 255, 0.029) 100%);
    background: -webkit-linear-gradient(162deg, $white-transparent200ish 0%, rgba(255, 255, 255, 0.029) 100%);
    background: linear-gradient(162deg, $white-transparent200ish 0%, rgba(255, 255, 255, 0.029) 100%);
  }

  &.Highlight {
    @include button-pulse-animation;
  }

  .Button__Icon {
    transform: scale(1);
  }

  &:active {
    -webkit-box-shadow: inset 9px 5px 10px 4px rgba(0, 0, 0, 0.317), inset -4px -2px 6px -1px rgba(255, 255, 255, 0.098); 
    box-shadow: inset 9px 5px 10px 4px rgba(0, 0, 0, 0.317), inset -4px -2px 6px -1px rgba(255,255,255, 0.098);
    color: rgb(230, 230, 230);

    .Button__Icon {
      transform: scale(.97);
    }
  }
}